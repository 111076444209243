const initBlock = async function( block ) {

	const slider = block.find( ".swiper-container" );
	const slide = block.find( ".swiper-slide" );
	const slideTitle = slide.find( "[data-slide-title]" );
	const slideImage = slide.find( "[data-slide-image]" );
	const prevNav = block.find( "[data-prev-nav]" );
	const prevNavImage = prevNav.find( "[data-prev-nav-image]" );
	const prevNavTitle = prevNav.find( "[data-prev-nav-title]" );
	const nextNav = block.find( "[data-next-nav]" );
	const nextNavImage = nextNav.find( "[data-next-nav-image]" );
	const nextNavTitle = nextNav.find( "[data-next-nav-title]" );

	if ( !slider ) {
		return;
	}

	const { Swiper } = await import( "swiper" );
	const { EffectFade, Navigation, Pagination } = await import( "swiper/modules" );
	await import( "swiper/css/bundle" );
	await import( "swiper/css/effect-fade" );

	const testimonialSwiper = new Swiper( slider[0], {
		slidesPerView: 1,
		loop: true,
		autoHeight: true,
		simulateTouch: false,
		effect: "fade",
		fadeEffect: {
			crossFade: true,
		},
		modules: [Navigation, Pagination, EffectFade],
		navigation: {
			prevEl: block.find( "[data-prev-el]" )[0],
			nextEl: block.find( "[data-next-el]" )[0],
		},
		pagination: {
			el: block.find( "[data-pagination-el]" )[0],
			type: "fraction"
		}
	} );

	block.find( "[data-prev-el]" ).on( "click", function( e ) {
		e.preventDefault();
		testimonialSwiper.slidePrev();
	} );

	block.find( "[data-next-el]" ).on( "click", function( e ) {
		e.preventDefault();
		testimonialSwiper.slideNext();
	} );

	testimonialSwiper.on( "slideChangeTransitionStart", function() {
		setNavData( testimonialSwiper.realIndex );
	} );

	function setNavData( currentSlideIndex ) {
		const prevSlideIndex = currentSlideIndex === 0 ? slide.length - 1 : currentSlideIndex - 1;
		const nextSlideIndex = currentSlideIndex === slide.length - 1 ? 0 : currentSlideIndex + 1;

		prevNav.addClass( "active" );
		nextNav.addClass( "active" );

		setTimeout( () => {
			prevNavTitle.text( slideTitle.eq( prevSlideIndex ).text() );
			nextNavTitle.text( slideTitle.eq( nextSlideIndex ).text() );
			prevNavImage.attr( "src", slideImage.eq( prevSlideIndex ).attr( "src" ) );
			nextNavImage.attr( "src", slideImage.eq( nextSlideIndex ).attr( "src" ) );

			prevNav.removeClass( "active" );
			nextNav.removeClass( "active" );
		}, 300 );
	}
};

themeUtils.loadBlock( initBlock, "testimonials", ".section-testimonials" );
